import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { initFormHandler } from '../../../../Handler/FormHandler';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../../Component/TitleComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import ButtonComponent from '../../../../Component/ButtonComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { getRoutePathname } from '../../../../Handler/RouteHandler';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import CheckboxComponent from '../../../../Component/CheckboxComponent';
import { hasRight } from '../../../../Common/UserCommon';

function BuyProductForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT') && !hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_EDIT');

    // Form
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom de l\'article.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        isActive: {
            name: 'isActive',
            label: 'Activé',
            textHelper: '',
            type: 'boolean',
            defaultValue: true,
            options: {}
        },
        sku: {
            name: 'sku',
            label: 'SKU',
            textHelper: 'Saisissez le SKU de l\'article.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        mark: {
            name: 'mark',
            label: 'Marque',
            textHelper: 'Choisissez la marque de l\'article.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        productType: {
            name: 'productType',
            label: 'Type',
            textHelper: 'Choisissez le type de l\'article.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        galenic: {
            name: 'galenic',
            label: 'Galénique',
            textHelper: 'Choisissez la galénique de l\'article.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        sector: {
            name: 'sector',
            label: 'Secteur',
            textHelper: 'Choisissez le secteur de l\'article.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        tva: {
            name: 'tva',
            label: 'TVA',
            textHelper: 'Choisissez le TVA de l\'article.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        family: {
            name: 'family',
            label: 'Famille',
            textHelper: 'Choisissez la famille de l\'article.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        subfamily: {
            name: 'subfamily',
            label: 'Sous Famille',
            textHelper: 'Choisissez la sous famille de l\'article.',
            type: 'integer',
            defaultValue: '',
            options: {}
        }
    });
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (props.product) {
                ApiHandler.post({
                    route: 'api_buy_product_edit',
                    data: handlerForm.getData(),
                    params: { id: params.id }
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Article modifié avec succès.',
                                variant: 'success',
                            })
                        );

                        props.setIsUpdateSylius(null);
                    } else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            } else {
                ApiHandler.post({
                    route: 'api_buy_product_add',
                    data: handlerForm.getData()
                }, (response) => {
                    if (response.status === 200) {
                        navigate(getRoutePathname('buy_product', { id: response.data.id }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Article créé avec succès.',
                                variant: 'success',
                            })
                        );
                        props.setIsUpdateSylius(null);
                    } else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };
    const generateSku = () => {
        if (props.product) {
            return;
        }

        const data = handlerForm.getData();

        let markCode = ''; // 2
        for (const index in optionsMark) {
            if (optionsMark[index].value === data.mark) {
                markCode = optionsMark[index].code ?? '';
            }
        }

        let galenicCode = ''; // 1
        for (const index in optionsGalenic) {
            if (optionsGalenic[index].value === data.galenic) {
                galenicCode = optionsGalenic[index].code ?? '';
            }
        }

        let productTypeCode = ''; // 1
        for (const index in optionsProductType) {
            if (optionsProductType[index].value === data.productType) {
                productTypeCode = optionsProductType[index].code ?? '';
            }
        }

        let nameCode = form.name.value ? form.name.value.replace(/\s/g, '') : ''; // 6

        if (nameCode.length < 6) {
            while (nameCode.length < 6) {
                nameCode = `${nameCode}0`;
            }
        }
        nameCode = nameCode.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        nameCode = nameCode.replace(/[^a-zA-Z0-9]/g, '');
        nameCode = nameCode.slice(0, 6);

        handlerForm.setValue('sku', (markCode + galenicCode + productTypeCode + nameCode).toUpperCase());
    };
    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        if (props.product) {
            setTimeout(() => handlerForm.setDataApi(props.product), 250);
        }
    }, [props.product]);
    React.useEffect(() => {
        handlerForm.setFormLoading(justWatch);
    }, [justWatch]);

    // Options
    const [optionsGalenic, setOptionsGalenic] = React.useState([]);
    const [optionsMark, setOptionsMark] = React.useState([]);
    const [optionsProductType, setOptionsProductType] = React.useState([]);
    const [optionsSector, setOptionsSector] = React.useState([]);
    const [optionsTva, setOptionsTva] = React.useState([]);
    const [optionsFamily, setOptionsFamily] = React.useState([]);
    const [optionsSubfamily, setOptionsSubfamily] = React.useState([]);
    React.useEffect(() => {
        ApiHandler.get({ route: 'api_select_galenic' }, (response) => setOptionsGalenic(response.data));
        ApiHandler.get({ route: 'api_select_mark' }, (response) => setOptionsMark(response.data));
        ApiHandler.get({ route: 'api_select_product_type' }, (response) => setOptionsProductType(response.data));
        ApiHandler.get({ route: 'api_select_sector' }, (response) => setOptionsSector(response.data));
        ApiHandler.get({ route: 'api_select_tva' }, (response) => setOptionsTva(response.data));
        ApiHandler.get({ route: 'api_select_family' }, (response) => setOptionsFamily(response.data));
    }, []);
    React.useEffect(() => {
        ApiHandler.get({ route: 'api_select_subfamily', data: { family: form.family.value } }, (response) => {
            const data = response.data;
            setOptionsSubfamily(data);
            let inData = false;
            for (const index in data) {
                if (data[index].value === form.subfamily.value) {
                    inData = true;
                }
            }

            if (!inData) {
                handlerForm.setValue('subfamily', '');
            }
        });
    }, [form.family.value]);

    return (
        <ShadowBoxComponent className={classes.shadowBox}>
            <TitleComponent title={'Information article'} />

            <Grid container spacing={1}>
                <CheckboxComponent orientation={'left'} id={'isActive'} handler={handlerForm} style={{ position: 'absolute', top: 0, right: 5, width: 'auto' }} />
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextFieldComponent id={'sku'} handler={handlerForm} disabled={true} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextFieldComponent id={'name'} handler={handlerForm} onBlur={generateSku} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectComponent id={'tva'} options={optionsTva} handler={handlerForm} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <SelectComponent id={'family'} options={optionsFamily} handler={handlerForm} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <SelectComponent id={'subfamily'} options={optionsSubfamily} handler={handlerForm} onBlur={generateSku} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectComponent id={'galenic'} options={optionsGalenic} handler={handlerForm} onBlur={generateSku} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectComponent id={'productType'} options={optionsProductType} handler={handlerForm} onBlur={generateSku} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectComponent id={'mark'} options={optionsMark} handler={handlerForm} onBlur={generateSku} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectComponent id={'sector'} options={optionsSector} handler={handlerForm} />
                </Grid>
            </Grid>

            {!justWatch && <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm} />}
        </ShadowBoxComponent>

    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    purveyorContent: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '0 0 5px 5px',
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%)',
        padding: 5,
        fontSize: 11,
        margin: '5px 0'
    },
    purveyorText: {
        flex: 5,
        fontSize: 11,
        lineHeight: '18px'
    },
    purveyorTrash: {
        flex: 1,
        cursor: 'pointer',
        fontSize: 11,
        height: '20px !important',
        width: '20px !important',
        color: '#5E6E82'
    }
});

export default BuyProductForm;
